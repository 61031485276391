import { PureComponent } from 'react';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { thunks as ccTicketThunks } from 'store/tickets/cc-tickets';
import { thunks as myTicketThunks } from 'store/tickets/my-tickets';
import { thunks as companyTicketThunks } from 'store/tickets/company-tickets';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  CcTickets,
  MyTickets,
  CompanyTickets,
} from 'modules/tickets/components';
import { ApplicationState } from 'store';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  LoadingSpinner,
} from 'shared/components';

interface TabNames {
  myTickets: string;
  ccTickets: string;
  companyTickets: string;
}

const tabNames: TabNames = {
  myTickets: 'myTickets',
  ccTickets: 'ccTickets',
  companyTickets: 'companyTickets',
};

const mapStateToProps = (state: ApplicationState) => ({
  translationsState: state.i18n,
  ticketsState: state.tickets,
  settingsState: state.settings,
});

const mapDispatchToProps = (
  dispatch: Dispatch // used to be generic <CcTicketsActions & MyTicketsActions>
) =>
  bindActionCreators(
    {
      requestMyTicketsThunk: myTicketThunks.requestTickets,
      requestCcTicketsThunk: ccTicketThunks.requestTickets,
      requestCompanyTicketsThunk: companyTicketThunks.requestTickets,
    },
    dispatch
  );

type TicketListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class TicketList extends PureComponent<TicketListProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.props.requestMyTicketsThunk({ searchText: '', page: 1 });
    this.props.requestCcTicketsThunk();

    this.getCompanyTickets();
  }

  public componentDidUpdate() {
    this.getCompanyTickets();
  }

  private getCompanyTickets(): void {
    const {
      ticketsState: { companyTickets },
      settingsState,
    } = this.props;

    const shouldFetchCompanyTickets =
      !settingsState.loading && //                if settings are not loading,
      settingsState.error == null && //           had no errors
      settingsState.settings != null && //        and have been fetched
      settingsState.settings.isCompanyAdmin && // and the user is an admin of some company
      !companyTickets.loading && //               and company tickets are not already loading
      companyTickets.error == null && //          and they had no errors
      companyTickets.data == null; //             and haven't already been fetched
    if (shouldFetchCompanyTickets) this.props.requestCompanyTicketsThunk();
  }

  render() {
    const {
      translationsState: {
        loading: loadingTranslations,
        error: translationError,
        translations,
      },
      settingsState: { settings },
      ticketsState: { myTickets, ccTickets, companyTickets },
    } = this.props;

    const showLoading = loadingTranslations || translations === undefined;
    const showError = !loadingTranslations && translationError !== undefined;
    const showCompanyTickets = settings?.isCompanyAdmin ?? false;

    return (
      <>
        {showLoading && (
          <LoadingSpinner className="section-margin blockquote text-center" />
        )}
        {showError && <div>An error occured while fetching translations.</div>}
        {!showLoading && !showError && (
          <div>
            <Breadcrumbs>
              <BreadcrumbsItem href="/" title={translations!.CommonHome} />
              <BreadcrumbsItem title={translations!.TicketsRequests} active />
            </Breadcrumbs>

            <Tabs
              defaultActiveKey="requests"
              className="nav-tabs-blue"
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="requests" title={translations!.TicketsRequests}>
                <Card>
                  <Card.Body>
                    <Tabs
                      defaultActiveKey={tabNames.myTickets}
                      className="nav-tabs-underlined component-spacing"
                      id="uncontrolled-tab-example"
                    >
                      <Tab
                        eventKey={tabNames.myTickets}
                        title={translations!.TicketsMyRequests}
                      >
                        <MyTickets
                          translations={translations!}
                          myTicketList={myTickets}
                          onSearch={(ticketFilter) => {
                            this.props.requestMyTicketsThunk(ticketFilter);
                          }}
                        />
                      </Tab>

                      <Tab
                        eventKey={tabNames.ccTickets}
                        title={translations!.TicketsRequestsImCCdOn}
                      >
                        <CcTickets
                          loading={ccTickets.loading}
                          error={ccTickets.error}
                          data={ccTickets.data}
                          onSearch={this.props.requestCcTicketsThunk}
                        />
                      </Tab>

                      {showCompanyTickets && (
                        <Tab
                          eventKey={tabNames.companyTickets}
                          title={translations!.TicketsCompanyRequests}
                        >
                          <CompanyTickets
                            translations={translations!}
                            companyTicketList={companyTickets}
                            onSearch={(ticketFilter) => {
                              this.props.requestCompanyTicketsThunk(
                                ticketFilter
                              );
                            }}
                          />
                        </Tab>
                      )}
                    </Tabs>
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketList);
