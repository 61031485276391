import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';

import {
  AdminPanel,
  MyActionsPanel,
  MyServices,
  ProductsServicesNews,
  OtherServices,
  SkillDevelopmentNews,
} from 'modules/home/components';

import { thunks as serviceThunks } from 'store/services/actions';
import { thunks as psNewsThunks } from 'store/news/products-services';
import { thunks as sdNewsThunks } from 'store/news/skill-development';
import { LoadingSpinner } from 'shared/components';

const mapStateToProps = (state: ApplicationState) => ({
  translationsState: state.i18n,
});

const mapDispatchToProps = (
  dispatch: Dispatch // used to be generic <ServicesActions & NewsActions>
) =>
  bindActionCreators(
    {
      fetchServices: serviceThunks.requestServicesThunk,
      fetchProductsServicesNews: psNewsThunks.requestNewsThunk,
      fetchSkillDevelopmentNews: sdNewsThunks.requestNewsThunk,
    },
    dispatch
  );

type HomeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class Home extends PureComponent<HomeProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.props.fetchServices();
    this.props.fetchProductsServicesNews();
    this.props.fetchSkillDevelopmentNews();
  }

  render() {
    const { loading, error, translations } = this.props.translationsState;
    const showLoading = loading || translations === undefined;
    const showError = !loading && error !== undefined;

    return (
      <>
        {showLoading && (
          <LoadingSpinner className="section-margin blockquote text-center" />
        )}
        {showError && <div>An error occured while fetching translations.</div>}
        {!showLoading && !showError && (
          <div>
            <MyActionsPanel />
            <AdminPanel />
            <MyServices />
            <SkillDevelopmentNews />
            <ProductsServicesNews />
            <OtherServices />
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
